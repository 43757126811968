import React from "react";
import styled from "styled-components";
import ProjectCard from "../../Sections/Projects/Projectcard";

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  justify-content: space-between;
  min-height: 100vh; /* Set minimum height to 100% of the viewport height */
  background-color: #010116;
`;

const Container = styled.div`
  scroll-snap-align: center;
  padding-top: 100px;
  flex: 1; /* Use flex to push content to the bottom */
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;

function Projects() {
  return (
    <>
      <Section>
        <Container>
          <div className="px-6 py-10 mx-auto sm:px-6 lg:px-8 max-w-7xl text-center">
            <h1 className="text-3xl font-bold leading-tight text-transparent bg-clip-text  bg-gradient-to-r from-teal-400 to-yellow-200 sm:text-4xl lg:text-5xl text-center">
              项目案例
            </h1>
             <p className="max-w-7xl mx-auto my-10 text-justify text-xl font-bold leading-tight text-transparent bg-clip-text  bg-gradient-to-r from-amber-500 to-pink-500">
             此模块展示了我们在3D可视化技术领域的独特应用和卓越成果。各个行业中的精彩案例，包括数创孪生系统、云展厅、产品展示等领域。我们利用先进的可视化技术，将客户的想法和概念转化为生动的视觉呈现，帮助他们实现项目目标并提升品牌价值。每个案例都详细展示了我们在项目策划、设计、制作和交付方面的专业能力和创新思维，突出了我们的行业经验和解决问题的能力。无论您是寻求效果图、产品展示视频还是仿真演示动画，我们都能为您提供定制化的解决方案，满足您的需求并超越您的期望。
                </p>
          </div>

          <div className="center-block">
            <ProjectCard
              projectData={{
                title: "3D扶贫云展馆",
                description:
                  "3D扶贫云展馆是一项融合先进数字化技术与3D可视化技术的创新项目。通过高度逼真的三维虚拟场景，展示贫困地区的实际情况、政策项目的实施情况以及未来发展规划，为观众提供直观、生动的参观体验。项目应用广泛，可用于公众宣传、政府政策推广、企业社会责任展示等领域，具有重要的推广和应用价值。通过数字化展示和3D技术的结合，3D扶贫云展馆为扶贫事业的宣传、推广和发展提供了新的途径和平台，促进了社会各界对扶贫工作的理解和支持，为实现减贫目标做出了积极贡献。",
                techUsed: "HTML CSS Three",
                ownerName: "Andy QU",
                deploymentLink: "https://show.freecomzh.com/ZGRS/ZGRS/index.html",
                githubLink: "",
                imageUrl: "",
                projectImage: "/img/project-images/fpyzt3d.jpg",
              }}
            />
            <ProjectCard
              projectData={{
                title: "3D电子琴",
                description:
                  "3D电子琴是一项创新的营销宣传项目，通过先进的3D技术，将电子琴产品以逼真的三维形式展示给观众。该项目突出了数字化展示和虚拟演奏的特点，使观众可以近距离观察电子琴的外观设计和操作界面，感受其细节和音乐魅力。3D技术为产品展示、营销宣传和品牌推广提供了全新的手段和平台，通过制作宣传视频、广告海报等材料，增加了产品的视觉冲击力和情感共鸣，促进了销售增长。为电子琴产品的推广和销售打开了新的可能性，为品牌树立了良好的形象，为行业发展带来了新的动力。",
                techUsed: "HTML CSS Three",
                ownerName: "Andy QU",
                deploymentLink: "https://show.freecomzh.com/dianziqin/index.html",
                githubLink: "",
                imageUrl: "",
                projectImage: "/img/project-images/dianziqin3d.jpg",
              }}
            />
          </div>

          <p className="max-w-2xl mx-auto my-6 text-center "></p>
        </Container>
      </Section>
    </>
  );
}

export default Projects;
