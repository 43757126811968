import React from "react";
import headerModule from "./headerModule";

function textModule(item) {
    return (
        <div
            className="overflow-hidden bg-slate-950 rounded text-white p-4"
            key={item.image}
        >
            {headerModule(item.name, item.subName)}
            <div className="program-content flex flex-col md:flex-row flex-1">
                {item.subItem.map(sub => {
                    return(
                        <div key={sub.name} className="program-content p-10 m-3" style={{
                            width:'auto', flex:1, backgroundRepeat: "no-repeat",
                            backgroundPosition: "50%",
                            backgroundSize: "100% 100%",
                            backgroundImage: "url(https://51aes.com/images/solution/cim/worth-item-bg.png)"}}>
                            <div className="center-block mb-4">
                                <img src={sub.image} alt={sub.name} style={{height:'3.5rem', width:'3.5rem'}}/>
                            </div>
                            <div className="text-2xl font-bold text-center mb-8">
                                {sub.name}
                            </div>
                            <div className="text-lg" style={{color:"hsla(0,0%,100%,.8)"}}>
                                {sub.content}
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default textModule;
