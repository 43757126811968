export const ResourceMain = [
  {
    name: "数字孪生系统平台",
    // to: "/webd",
    description:
      "以虚拟现实技术为核心，实现物理世界与数字世界的无缝对接。通过精准数据采集、高效模型构建与智能仿真分析，助力企业预测未来、优化决策，实现业务价值的最大化，根据用户的具体需求，提供定制化的可视化解决方案，满足用户的个性化需求。 ",
    image: require("./ResourceIcons/digitalTwin.jpg"),
  },
  {
    name: "仿真计算3D可视",
    description:
      "利用强大的计算引擎，进行精确的数据模拟和分析，再结结合WebGL的高效图形渲染能力，灵活性与易用性，将复杂的计算结果以直观的三维形式展示出来。无论是工程设计、科学研究还是教育培训，本产品都能帮助用户更好地理解数据，发现规律，优化决策。",
    // to: "/android",
    image: require("./ResourceIcons/simulation.jpg"),
  },
  {
    name: "产品3D展示",
    // to: "/aiml",
    description:
      "本产品是一款多行业通用的3D展示解决方案，适用于汽车、文物、珠宝、服装、家具、电器等多个领域。通过高精度3D建模和渲染技术，将实物以逼真的三维形式呈现，让用户可以全方位、多角度地观察产品细节。同时，结合交互式操作，用户可以自由旋转、缩放、移动产品，获得更加直观、生动的展示体验。",
    image: require("./ResourceIcons/BJ90.jpg"),
  },
  {
    name: "3D云展厅",
    description:
      "3D云展厅是一种基于WebGL的线上展览平台，具备漫游和交互等丰富功能。用户可以自由探索虚拟展厅，体验身临其境的漫游感受。同时，通过丰富的交互设计，用户可以深入了解展品信息，提升展览的趣味性和吸引力。3D云展厅打破时空限制，为用户带来全新的线上展览体验，广泛应用于各大展会、博物馆等领域。",
    // to: "/dataScience",
    image: require("./ResourceIcons/cloudGallery.jpg"),
  },
  {
    name: "影视游戏建模",
    // to: "/webd",
    description:
      "本产品专注于影视与游戏行业的3D建模服务，利用先进的建模技术和丰富的行业经验，为客户打造精致逼真的虚拟世界。无论是电影中的场景重现、角色塑造，还是游戏中的复杂环境、细腻角色设计，我们都能提供高质量的3D模型。 ",
    image: require("./ResourceIcons/movieModel.jpg"),
  },
  {
    name: "园林景观设计",
    description:
      "本产品专注于园林景观的规划与设计，结合自然美学与人文理念，打造独特的园林空间。我们注重生态、功能与文化的融合，通过精心策划与细致执行，呈现出多样化的园林景观。让每一处景观都成为城市中的亮点。",
    // to: "/android",
    image: require("./ResourceIcons/parkModel.jpg"),
  },
  {
    name: "网站/独立站开发",
    // to: "/webd",
    description:
      "本产品旨在为客户量身打造个性化、功能齐全的网络平台。我们结合用户需求与市场趋势，运用先进的开发技术，打造独特且易于使用的网站界面。我们注重网站性能优化与用户体验提升，确保网站稳定、安全、高效运行。 ",
    image: require("./ResourceIcons/webSite.jpg"),
  },
  {
    name: "小程序/APP开发",
    description:
      "本产品我们致力于为客户打造功能丰富、用户体验优良的应用产品。通过深入了解客户需求和市场趋势，我们运用前沿的开发技术，为客户量身定制独特且实用的应用界面和功能。注重应用的性能优化和用户体验提升，确保应用稳定、流畅、易用。",
    // to: "/android",
    image: require("./ResourceIcons/miniProgram.jpg"),
  },
  {
    name: "DevOps 服务",
    description:
      " DevOps服务旨在通过集成高效工具与流程，优化企业软件开发与运维。我们专注搭建稳定、高效的Git环境，实现代码的版本控制与团队协作的无缝对接。同时，依托CI/CD自动化部署，确保软件开发的持续集成与快速交付，提升开发效率与软件质量。",
    // to: "/devops",
    image: require("./ResourceIcons/devops.png"),
  },
  {
    name: "UI/UX 设计",
    description:
      " UI/UX服务专注于为用户打造卓越的数字体验。深入洞察用户需求，设计直观易用的界面，确保用户能够轻松上手并享受操作过程。同时，我们注重用户体验的持续优化，通过精细化的交互设计和流畅的动效，提升用户满意度和忠诚度。",
    // to: "/uiux",
    image: require("./ResourceIcons/uiux.png"),

  },

];
