import React from "react";
import styled from "styled-components";
import './team.css';

const CardWrapper = styled.div`
  width: 300px;
  height: 350px;
  position: relative;
  perspective: 1000px;
  transition:transform 0.3s ease;
  &:hover {
    transform: scale(1.05);
  }

  ul li{
    cursor: pointer;
  }

  @media only screen and (max-width: 768px) {
    width: 300px;
    height: 350px;
  }
`;


function DataCard({ image, name, position }) {
  return (
    <CardWrapper className="my-5">
      <div className="card flex justify-center">
        <img
          src={image}
          alt="Person"
          className="datacard__image"
        />
        <p className="datacard__name">{name}</p>
        <p className="card__role">{position}</p>
  
      </div>
    </CardWrapper>
  );
}

export default DataCard;
