import React from "react";
import styled from "styled-components";

const SubName = styled.div`
  letter-spacing: 0.3rem;
  position: relative;
  padding: 0.5rem 1rem 1rem 1rem;
  font-size: 0.75rem;
  :after {
    position: absolute;
    content: "";
    width: 40px;
    height: 3px;
    background-color: #3369ff;
    left: 50%;
    bottom: 0;
    transform: translate(-50%);
  }
`;

function textModule(name, subName) {
  return (
    <div className="overflow-hidden bg-slate-950 rounded text-white p-4" key={name}>
      <div className="text-center text-lg md:text-2xl lg:text-4xl font-bold mb-3 mt-8" style={{ letterSpacing: "0.15em" }}>
        {name}
      </div>
      <SubName className="text-center font-bold ">{subName}</SubName>
    </div>
  );
}

export default textModule;
