import React from "react";
import picModule from "../modules/picModule";
import videoModule from "../modules/videoModule";
import textModule from "../modules/textModule";
import headerModule from "../modules/headerModule";

function intelligentCity() {
  return (
    <div className="overflow-hidden bg-slate-950 rounded text-white p-4" key={1}>
      <div className="text-center text-xl md:text-2xl lg:text-5xl font-bold mb-12 mt-8">智慧电力</div>
      {textModule({
        name: "方案价值",
        subName: "PRODUCT VALUE",
        subItem: [
          {
            name: "智能监控，稳定供电",
            content: "通过智能电表和传感器网络，实时监控电网运行状态，精准调整电力供应，确保供电稳定，减少停电事故，提高电网运行效率",
            image: "./img/scys01.png",
          },
          {
            name: "能源优化，降低损耗",
            content: "利用大数据分析和人工智能算法，优化能源分配，平衡供需，减少电力传输过程中的损耗，提高能源利用率，降低运营成本。",
            image: "./img/scys11.png",
          },
          {
            name: "实时分析，预防故障",
            content: "实时采集和分析电网数据，利用预测性维护技术，提前识别和预防设备故障，减少突发事故，延长设备寿命，提升供电可靠性。",
            image: "./img/scys12.png",
          },
        ],
      })}
      {headerModule("业务架构", "ARCHITECTURE")}
      {picModule({ image: "./img/internal-programs/jiagou_dianli.png", name: "云原生" })}
      {headerModule("细分领域", "SUBDIVISION")}
      {picModule({ image: "./img/internal-programs/zhihuidianli.png", name: "云原生" })}
      {headerModule("典型案例", "TYPICAL CASE")}
      {videoModule(
        {
          name: "潍坊风电检测<br/>检测管控平台",
          description: `通过先进的数字化技术实现对风电场风机的实时监控和管理。项目利用传感器和物联网技术收集风机运行的各种数据，如风速、风向、发电量、振动、温度等，通过云平台进行大数据分析和处理。可视化界面通过3D模型直观展示每台风机的实时状态和历史运行数据，方便运维人员进行远程监控和诊断。系统还具备智能预警功能，通过AI算法预测可能出现的故障，提前安排维护，避免突发停机事件，提高风机的运行效率和可靠性。该项目不仅优化了风电场的运维管理，还为可再生能源的高效利用提供了技术支持，推动了智慧电力的发展。`,
          video: "https://obs.talsys.cn/demo_video/%E6%99%BA%E6%85%A7%E7%94%B5%E5%8A%9B_%E9%A3%8E%E7%94%B5%E6%A3%80%E6%B5%8B%E5%B9%B3%E5%8F%B0.mp4",
        },
        true
      )}
      {videoModule(
        {
          name: "国网电力<br/>检测管控平台",
          description: `为提升电力系统的监控和管理效率。该项目通过构建综合可视化平台，整合电力网络的各类数据源，包括电力输送、变电站监控、负荷预测等，提供实时数据分析和监控。平台利用大数据、人工智能等技术，实现电力运行状态的实时监控和智能预警，能够及时发现和处理潜在的电力故障和异常情况。此外，平台支持多维度数据可视化展示，通过直观的图表和地图，管理人员可以全面了解电力系统运行情况，做出准确的决策。项目还注重多部门协同，通过信息共享和应急预案，提升电力系统的应急响应能力。`,
          video: "https://obs.talsys.cn/demo_video/%E6%99%BA%E6%85%A7%E7%94%B5%E5%8A%9B_%E5%9B%BD%E7%BD%91%E7%94%B5%E5%8A%9B%E7%9B%91%E6%8E%A7.mp4",
        },
        false
      )}
      {videoModule(
        {
          name: "核引擎大装置<br/>数字孪生平台",
          description: `通过高精度数字孪生技术提升核电站的运营和管理效率。项目利用三维建模技术构建了核引擎及其相关大装置的虚拟模型，实现了设备运行状态的实时监控和数据分析。通过大数据和人工智能技术，平台可以对关键运行参数进行智能预测和故障预警，提供精准的维护和优化建议，确保设备的安全稳定运行。该平台支持多维度的数据展示和分析，帮助管理人员全面了解设备的运行情况，优化资源配置，提升整体管理效率。智慧电力核引擎大装置可视化孪生项目的实施，为核电站的智能化管理提供了先进的技术支持，推动了核电行业的数字化和智能化转型。`,
          video: "https://obs.talsys.cn/demo_video/%E6%99%BA%E6%85%A7%E7%94%B5%E5%8A%9B_%E6%A0%B8%E5%BC%95%E6%93%8E%E6%95%B0%E5%AD%97%E5%8C%96.mp4",
        },
        true
      )}
      {videoModule(
        {
          name: "电梯大装置<br/>数据可视化",
          description: `通过数字化手段提升电梯运维管理效率和安全性。该项目运用物联网技术采集电梯运行中的各项关键数据，包括运行速度、停层次数、开关门次数、故障报警等，通过实时数据监测和智能分析，对电梯的健康状态进行全面评估。平台通过3D可视化界面，直观展示电梯的运行轨迹、故障分布以及维保记录。结合大数据分析和AI预测，系统能够提前预警潜在故障，指导维保工作，减少停机时间，提升乘客安全性。智慧电力电梯数据监测可视化项目的实施，不仅提高了电梯管理的精细化水平，还为城市智慧化管理提供了重要的技术支撑，`,
          video: "https://obs.talsys.cn/demo_video/%E6%99%BA%E6%85%A7%E7%94%B5%E5%8A%9B_%E7%94%B5%E6%A2%AF%E5%8F%AF%E8%A7%86%E5%8C%96.mp4",
        },
        false
      )}
    </div>
  );
}

export default intelligentCity;
