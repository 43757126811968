import React, {useRef, useState} from "react";
import styled from "styled-components";
import ImageCarousel from "../../utils/ImageCrousel";
import { Carousel, Menu } from '@arco-design/web-react';
import { images } from "../../constants/constants";
import "@arco-design/web-react/dist/css/arco.css";

const MenuItem = Menu.Item;
const SubMenu = Menu.SubMenu;

const imageSrc = [
  {
    key: '1',
    name: '经营分析驾驶舱',
    info: '关键数据一屏掌控，实现业务统一指挥，资源及时调度',
    pic: 'img/fv-02-1.jpg'
  },

  {
    key: '2',
    name: '监控指挥中心',
    info: '关键数据一屏掌控，实现业务统一指挥，资源及时调度',
    pic: 'img/fv-02-2.jpg'
  },
  {
    key: '3',
    name: '生产车间看板',
    info: '搭建3D孪生车间，全面透视产线和设备运行及能耗状况',
    pic: 'img/fv-02-3.jpg'
  },
  {
    key: '4',
    name: '展厅大屏',
    info: '搭建3D孪生车间，全面透视产线和设备运行及能耗状况',
    pic: 'img/fv-02-4.jpg'
  },
  {
    key: '5',
    name: '汇报演示',
    info: '比PPT更具动感的数字化汇报演示，让汇报生动有趣、有理有据',
    pic: 'img/fv-02-5.jpg'
  }
];


const Section = styled.div`
    .carousel-select{
        color: white;
        font-weight: bold;
        border-bottom: 2px solid #0069EA;
    }
    .carousel-normal{
        color: #9ca3af;
        border-bottom: 2px solid transparent;
    }
  height: 100vh;
  width: 100%;
  scroll-snap-align: center;
  display: flex;
  justify-content: center;
  ${
    "" /* background: rgb(9, 9, 121);
  background: linear-gradient(
    262deg,
    rgba(9, 9, 121, 1) 0%,
    rgba(2, 0, 36, 1) 1%
  ); */
  }
  ${"" /* background: no-repeat url("./img/bg.jpg"); */}
  background-color: #010116;
  @media only screen and (max-width: 768px) {
    height: 80vh;
  }
`;

const About = () => {
  const [chooseItem, setChooseItem] = useState(0);
  function clickItem(idx) {
    return () => {
      setChooseItem(idx)
    }
  }

  return (
    <>
      <Section style={{display: 'block', marginTop: '2rem' }}>
        <div style={{ textAlign: "center", width: "100%", height:"fit-content", fontSize: "2rem" }}>
        <h2 className="text-xl  md:text-4xl lg:text-5xl font-bold mb-16 mt-12">
              数创{" "}
              <span className="text-2xl md:text-4xl lg:text-5xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-blue-800">
              应用
              </span>{" "}
            </h2>
        </div>
          <div className='menu-demo' style={{display: "flex", justifyContent: "space-between", width: "80%",
            height:"fit-content", margin:"1.5rem auto", cursor: "pointer", borderBottom: "1px solid rgba(200,200,200,0.7)"}} onClick={clickItem}>
            {imageSrc.map((item, index) => (
              <div key={index.toString()} onClick={clickItem(index, item)} style={{fontSize: "1rem", padding: '1rem'}}
              className={chooseItem === index ? 'carousel-select' : 'carousel-normal'}>{item.name}</div>
            ))}
          </div>
        <Carousel
          autoPlay={false}
          currentIndex={chooseItem}
          animation='card'
          indicatorType='never'
          style={{width: '85vw', height: "fit-content", margin: '2em auto 0'}}
          onChange={setChooseItem}
        >
          {imageSrc.map((src, index) => (
            <div
              key={index}
              style={{width: '80%'}}
            >
              <div style={{
                width: '100%',
                textAlign: 'center',
                fontWeight: 'bolder',
                marginBottom: '1.5rem',
                fontSize: '1.5rem'
              }}>
                “&nbsp;{src.info}&nbsp;”
              </div>
              <img
                src={src.pic}
                style={{width: '100%', height: '30vw', border: '1px white solid', borderRadius: '1em'}}
              />
            </div>
          ))}
        </Carousel>
      </Section>
    </>
  );
};

export default About;
