import React from "react";
import picModule from "../modules/picModule";
import videoModule from "../modules/videoModule";
import textModule from "../modules/textModule";
import headerModule from "../modules/headerModule";

function intelligentCity() {
  return (
    <div className="overflow-hidden bg-slate-950 rounded text-white p-4" key={1}>
      <div className="text-center text-xl md:text-2xl lg:text-5xl font-bold mb-12 mt-8">安全应急</div>
      {textModule({
        name: "方案价值",
        subName: "PRODUCT VALUE",
        subItem: [
          {
            name: "数据共享，提升效率",
            content: "通过建立统一的数据平台，各部门共享实时信息，减少信息孤岛，实现资源整合，提高应急响应和决策效率。",
            image: "./img/scys01.png",
          },
          {
            name: "智能调度，缓解拥堵",
            content: "运用智能算法分析交通流量和事件数据，自动调整交通信号和路线规划，优化交通流，减少拥堵，提高通行效率。",
            image: "./img/scys11.png",
          },
          {
            name: "动态监控，保障安全",
            content: "利用实时监控系统和传感器，持续跟踪关键区域和设施状态，及时发现异常，确保安全预警和快速响应，降低安全风险。",
            image: "./img/scys12.png",
          },
        ],
      })}
      {headerModule("业务架构", "ARCHITECTURE")}
      {picModule({ image: "./img/internal-programs/jiagou_yingji.png", name: "云原生" })}
      {headerModule("细分领域", "SUBDIVISION")}
      {picModule({ image: "./img/internal-programs/anquanyingji.png", name: "云原生" })}
      {headerModule("典型案例", "TYPICAL CASE")}
      {videoModule(
        {
          name: "某地区智慧消防<br/>可视化平台",
          description: `华东地区的某市，智慧消防系统项目通过物联网、大数据和人工智能技术，实现了城市消防管理的智能化和实时化。项目旨在全面监控重点消防区域，提升火灾预防能力；利用智能调度系统提高响应效率，优化消防资源配置。数据分析与预测功能增强了预警能力，而公众参与平台则提高了市民的消防安全意识。通过实施设备安装、系统集成和全面推广，该项目显著提升了城市消防管理水平，确保市民生命财产安全，并为其他城市提供了可借鉴的智慧消防模式。`,
          video: "https://obs.talsys.cn/demo_video/%E5%AE%89%E5%85%A8%E5%BA%94%E6%80%A5_%E6%9F%90%E5%9C%B0%E5%8C%BA%E6%99%BA%E6%85%A7%E6%B6%88%E9%98%B2.mp4",
        },
        true
      )}
      {videoModule(
        {
          name: "厦门沿海防汛<br/>可视化平台",
          description: `为提升防汛管理和应急响应能力，通过建立综合可视化平台，实现对沿海防汛的全方位监控与管理。项目采用物联网、大数据、云计算等技术，整合多种数据源，包括气象预报、雨量监测、水位监测等，提供实时的防汛信息。平台具备智能预警功能，能够根据监测数据自动分析和预测潜在风险，及时发布预警信息，指导应急处置。同时，平台还支持多部门协同作战，通过可视化界面，管理者可以直观地掌握防汛资源分布、应急队伍位置和物资储备情况，快速调度应急资源，确保防汛工作的高效开展。`,
          video: "https://obs.talsys.cn/demo_video/%E5%AE%89%E5%85%A8%E5%BA%94%E6%80%A5_%E5%8E%A6%E9%97%A8%E6%B2%BF%E6%B5%B7%E9%98%B2%E6%B1%9B.mp4",
        },
        false
      )}
      {videoModule(
        {
          name: "LNG接收站<br/>可视化平台",
          description: `在提升液化天然气（LNG）接收站的安全管理和应急响应能力。该项目通过建立一个综合性的监控和管理平台，实现对LNG接收、存储、气化及输送全过程的实时监控。系统集成了视频监控、环境监测、设备状态监测等多种传感器数据，提供全面的运行状态可视化。借助物联网和大数据分析技术，平台能够进行风险预测和预警，及时发现并处理潜在的安全隐患。此外，系统还设有应急预案管理和应急指挥功能，支持事故模拟演练和应急资源调度，确保在突发事件中能够快速响应和有效处置。`,
          video: "https://obs.talsys.cn/demo_video/%E5%AE%89%E5%85%A8%E5%BA%94%E6%80%A5_lng%E6%8E%A5%E6%94%B6%E7%AB%99%E5%8F%AF%E8%A7%86%E5%8C%96.mp4",
        },
        true
      )}
    </div>
  );
}

export default intelligentCity;
