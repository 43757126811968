import React from "react";
import styled from "styled-components";
import { allmembers } from "../../constants/constants";
import TeamCard from "../../Sections/Teams/TeamCard";

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  display: flex;
  ${"" /* background-color: rgb(9, 9, 121);
  background-repeat: no-repeat;
  background: linear-gradient(
    262deg,
    rgba(9, 9, 121, 1) 0%,
    rgba(2, 0, 36, 1) 1%
  ); */
  }
  background-color: #010116;
  ${"" /* background-repeat: no-repeat;
  background: no-repeat url("./img/bg.jpg"); */
  }
`;

const Container = styled.div`
  scroll-snap-align: center;
  width: 1440px;
  padding-top: 100px;
  @media only screen and (max-width: 738px) {
    width: 100vw;
    justify-content: center;
    align-items: center;
  }
  @media only screen and (max-width: 1030px) {
    width: 100vw;
    justify-content: center;
    align-items: center;
  }

  @media only screen and (max-width: 1030px) {
    width: 100vw;
    justify-content: center;
    align-items: center;
  }
`;

function AllTeams() {
  return (
    <>
      <Section>
        <Container>
          <div className=" px-6 py-10 ">
            <h2 className="text-3xl font-bold leading-tight text-transparent bg-clip-text  bg-gradient-to-r from-teal-400 to-yellow-200 sm:text-4xl lg:text-5xl text-center">
              关于我们
            </h2>
            <p className="max-w-7xl mx-auto my-10 text-justify  text-xl font-bold leading-tight text-transparent bg-clip-text  bg-gradient-to-r from-amber-500 to-pink-500 ">
              泰安鲁思元数致力于打造一个全新的数字孪生世界，用科技改变世界。我们专注于为政府和企业客户提供卓越的数字孪生开发与应用服务，助力客户实现数字化转型。

              我们的核心团队汇聚了各业界顶尖的专家和工程师，在数字孪生技术领域拥有丰富的经验和深厚的专业背景。我们紧跟科技趋势，不断探索创新，将最先进的数字孪生技术应用于实际场景，为客户创造价值。

            </p>
            <p className="max-w-7xl mx-auto my-10 text-justify text-xl font-bold leading-tight text-transparent bg-clip-text  bg-gradient-to-r from-amber-500 to-pink-500 ">

              四元可视化作为泰安鲁思元数旗下的数字孪生平台，我们的服务涵盖了从数字孪生平台的规划、设计、开发到应用的全方位服务，可根据客户的需求量身定制解决方案。我们致力于帮助客户提升运营效率、降低成本、实现可持续发展。

              在未来，鲁思元数将继续深耕数字孪生领域，坚持创新驱动，以卓越的技术和专业的服务，助力更多企业实现数字化转型，共创美好未来。
            </p>

            <div className="flex items-center justify-center">
              <a
                href="https://drive.google.com/file/d/1zvfboD5gVlyCwf3LVGRGYaqpwe2MtPLC/view"
                target="_blank"
                rel="noreferrer"
              >
                {/* <button
                  type="button"
                  className="text-white mt-1 bg-blue-700 font-medium rounded-lg text-sm px-8 py-2.5 text-center mr-0 mb-2"
                >
                  View all the members
                </button> */}
              </a>
            </div>

            <div className="flex items-center justify-center">
              <a
                href="https://drive.google.com/file/d/1gnPXTsHmbl0s8gTNkrzLRy8dZFxZ77eV/view"
                target="_blank"
                rel="noreferrer"
              >
                {/* <button
                  type="button"
                  className="text-white mt-1 bg-blue-700 font-medium rounded-lg text-sm px-8 py-2.5 text-center mr-0 mb-2"
                >
                  Position Holders 2023-24
                </button> */}
              </a>
            </div>

            <div className="flex justify-center items-center">
              <div className="my-grid">
                {allmembers.map((member) => {
                  return (
                    <TeamCard
                      key={member.name}
                      image={member.image}
                      name={member.name}
                      position={member.position}
                      instagram={member.instagram}
                      twitter={member.twitter}
                      linkedin={member.linkedin}
                      github={member.github}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </Container>
      </Section>
    </>
  );
}

export default AllTeams;
