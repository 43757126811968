/*
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2024-01-10 10:15:17
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2024-03-25 18:07:57
 * @FilePath: \officialWebsite\src\Sections\SocialMedia\SocialMedia.jsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React from "react";
import styled from "styled-components";

const Section = styled.div`
  height: 100%;
  background-color: #010116;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 2rem 0;
`;

const YoutubeFrame = styled.iframe`
  width: 30vw;
  height: 350px;

  @media only screen and (max-width: 768px) {
    width: 90vw;
    height: 250px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;

const SocialMedia = () => {
  return (
    <>
      <h2 className="mt-[100px] text-2xl text-center md:text-4xl lg:text-5xl font-bold">
       数创{" "}
        <span className="text-2xl md:text-4xl lg:text-5xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-blue-800">
          平台
        </span>
      </h2>
      <Section>
        <div className="grid grid-cols-1 lg:grid-cols-3 justify-items-center">
        <img src="img/szdq.jpg" alt="" style={{ width: '300px', height: '300px' }} />
        <img src="img/szls.jpg" alt="" style={{ width: '300px', height: '300px' }} />
        <img src="img/szfz.jpg" alt="" style={{ width: '300px', height: '300px' }} />
          {/* <YoutubeFrame src="https://www.bilibili.com/video/BV1xC4y1E7d9/?spm_id_from=333.999.0.0&vd_source=30af7a809ab8ef8657cb6924341d8459"/>
          <YoutubeFrame src="https://www.youtube.com/embed/yUD27BJf5ag?si=D6mMdihaQc5a4h6d" />
          <YoutubeFrame src="https://www.youtube.com/embed/TrPl1K4UBXI?si=hyQjEEqEAIJE-JxT" /> */}
        </div>
      </Section>
    </>
  );
};

export default SocialMedia;
