export const PastEvents = [
    {
        "date": "2024/05/08",
        "name": "小米SU7向光而行",
        "image": "./img/events/su7020508.jpg"
    },{
        "date": "2024/04/25",
        "name": "AIPC要来了？",
        "image": "./img/events/aipc20240425.jpg"
    },
    {
        "date": "2024/04/20",
        "name": "生日会活动",
        "image": "./img/events/srh240421.jpg"
    },
    {
        "date": "2023/12/06",
        "name": "GDBT框架",
        "image": "./img/events/GDBT.jpg"
    },
    {
        "date": "2023/10/24",
        "name": "程序员日",
        "image": "./img/events/tj231024.jpg"
    },
    {
        "date": "2023/08/21",
        "name": "菏泽交投商务投标",
        "image": "./img/events/hzjtst.jpg"
    },
    {
        "date": "2023/06/03",
        "name": "夏季度假团建",
        "image": "./img/events/tj230630.jpg"
    },
    {
        "date": "2023/03/06",
        "name": "圣瑞需求对接",
        "image": "./img/events/srskxm.jpg"
    },
    {
        "date": "2022/11/30",
        "name": "OpenAI发布Gpt",
        "image": "./img/events/gpt221130.jpg"
    },
    {
        "date": "2022/09/16",
        "name": "羽毛球比赛",
        "image": "./img/events/swhd220916.jpg"
    },
    {
        "date": "2022/05/29",
        "name": "太倉古镇项目-云渲染",
        "image": "./img/events/tcgzyxr.jpg"
    },
    {
        "date": "2022/04/10",
        "name": "阿里云展厅项目",
        "image": "./img/events/alyztxm.jpg"
    },
    {
        "date": "2022/02/19",
        "name": "新时代网络文明展",
        "image": "./img/events/xsdwm.jpg"
    },
    {
        "date": "2022/01/06",
        "name": "办公驻地雪景",
        "image": "./img/events/gsxj200106.jpg"
    },

    {
        "date": "2021/11/13",
        "name": "交控轨交论坛",
        "image": "./img/events/jk211113.jpg"
    },
    {
        "date": "2021/09/15",
        "name": "东营马拉松团建",
        "image": "./img/events/mlstj.jpg"
    },
    {
        "date": "2021/09/03",
        "name": "中国国际服贸交易会",
        "image": "./img/events/fmh210903.jpg"
    },
    {
        "date": "2021/08/26",
        "name": "天津项目驻场",
        "image": "./img/events/tj230826.jpg"
    },
    {
        "date": "2021/07/13",
        "name": "泰宏3D技术培训",
        "image": "./img/events/jspx.jpg"
    },
    {
        "date": "2021/05/12",
        "name": "创客小镇沙盘项目",
        "image": "./img/events/ckxz210512.jpg"
    },

];
