import React from "react";
import picModule from "../modules/picModule";
import videoModule from "../modules/videoModule";
import textModule from "../modules/textModule";
import headerModule from "../modules/headerModule";

function intelligentCity() {
  return (
    <div className="overflow-hidden bg-slate-950 rounded text-white p-4" key={1}>
      <div className="text-center text-xl md:text-2xl lg:text-5xl font-bold mb-12 mt-8">智慧矿山</div>
      {textModule({
        name: "方案价值",
        subName: "PRODUCT VALUE",
        subItem: [
          {
            name: "智能采矿，提质增效",
            content: "采用先进的自动化和智能设备，通过精准控制和优化流程，提高矿石回收率，减少人工操作，显著提升生产效率和矿石质量。",
            image: "./img/scys01.png",
          },
          {
            name: "实时监测，保障安全",
            content: "利用传感器网络和物联网技术，对矿山环境、设备状态和人员位置进行实时监测，及时预警潜在安全隐患，确保矿工安全。",
            image: "./img/scys11.png",
          },
          {
            name: "生态友好，绿色发展",
            content: "采用绿色开采技术，降低对环境的影响，实时监控污染源，实施生态修复和治理措施，实现矿山的可持续发展和生态保护。",
            image: "./img/scys12.png",
          },
        ],
      })}
      {headerModule("业务架构", "ARCHITECTURE")}
      {picModule({ image: "./img/internal-programs/jiagou_kuangshan.png", name: "云原生" })}
      {headerModule("细分领域", "SUBDIVISION")}
      {picModule({ image: "./img/internal-programs/zhihuikuangshan.png", name: "云原生" })}
      {headerModule("典型案例", "TYPICAL CASE")}
      {videoModule(
        {
          name: "矿山地质<br/>管理平台",
          description: `通过融合CAE仿真技术、物联网、大数据分析和三维可视化技术，实现了矿山地质信息的全面数字化和可视化管理。平台能够实时监测矿山地质环境，精确定位和评估地质灾害风险，提高矿山生产的安全性和效率。通过CAE仿真技术，平台可以模拟和预测矿山开采过程中的各种场景，优化开采策略，降低运营成本。同时，平台具备良好的扩展性和兼容性，能够与其他矿山管理系统无缝对接，为矿山企业提供一体化的管理解决方案。`,
          video: "https://obs.talsys.cn/demo_video/%E6%99%BA%E6%85%A7%E7%9F%BF%E5%B1%B1_%E5%9C%B0%E8%B4%A8%E7%AE%A1%E7%90%86.mp4",
        },
        true
      )}
      {videoModule(
        {
          name: "青岛市某高炉<br/>数字孪生平台",
          description: `通过构建3D可视化平台，将高炉内外部的各类数据进行实时展示，包括温度、压力、气流等关键参数。系统集成了传感器数据、历史运行数据和生产调度信息，利用大数据分析和AI技术实现故障预测、能效优化和安全预警。用户可以通过可视化界面直观了解高炉的运行状态，并进行远程监控和操作。此外，系统还支持多种场景下的模拟和培训，提高了操作人员的应急处理能力和整体管理水平。不仅显著提升了高炉的生产效率，还在保障安全生产和节能减排方面发挥了重要作用。`,
          video: "https://obs.talsys.cn/demo_video/%E6%99%BA%E6%85%A7%E7%9F%BF%E5%B1%B1_%E9%AB%98%E7%82%89%E5%8F%AF%E8%A7%86%E5%8C%96.mp4",
        },
        false
      )}
      {videoModule(
        {
          name: "某矿山采矿机<br/>数字孪生平台",
          description: `构建数字孪生模型，实现对采矿设备的实时监控与智能管理。该平台整合了物联网传感器、云计算、大数据分析和人工智能技术，能够实时采集采矿机的运行数据，生成动态的三维数字模型，反映设备的真实状态。通过这些数字孪生模型，管理人员可以直观地查看设备运行情况，进行远程诊断和故障预测，优化设备维护计划，提升运营效率。此外，平台还具备模拟仿真功能，帮助制定更加精准的采矿方案，减少能源消耗和设备损耗，确保生产安全和环保合规。该项目的实施不仅提高了采矿作业的智能化水平，还推动了矿山管理的数字化转型。`,
          video: "https://obs.talsys.cn/demo_video/%E6%99%BA%E6%85%A7%E7%9F%BF%E5%B1%B1_%E9%87%87%E7%85%A4%E6%9C%BA%E6%95%B0%E5%AD%97%E5%AD%AA%E7%94%9F.mp4",
        },
        true
      )}
    </div>
  );
}

export default intelligentCity;
