/*
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2024-01-10 10:15:17
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2024-04-08 17:16:33
 * @FilePath: \officialWebsite\src\constants\programs.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

import module1 from "../Pages/Programs/modules/module1";
import module2 from "../Pages/Programs/modules/module2";
import picModule from "../Pages/Programs/modules/picModule";
import videoModule from "../Pages/Programs/modules/videoModule";
import intelligentCity from "../Pages/Programs/subpage/intelligentCity";
import safetyEmergency from "../Pages/Programs/subpage/safetyEmergency";
import intelligentElectricity from "../Pages/Programs/subpage/intelligentElectricity";
import intelligentRealestate from "../Pages/Programs/subpage/intelligentRealestate";
import intelligentDiggings from "../Pages/Programs/subpage/intelligentDiggings";
import intelligentWaterConservancy from "../Pages/Programs/subpage/intelligentWaterConservancy";
import intelligentTravel from "../Pages/Programs/subpage/intelligentTravel";
import intelligentMedical from "../Pages/Programs/subpage/intelligentMedical";
export const programsList = [
  {
    name: "智慧城市",
    description: `借助城市数字孪生平台作为核心基础，我们依托智慧物联、数据治理和人工智能等多方面的技术支持，构建了一个全面的业务枢纽。这个枢纽能够满足各种数字孪生应用的需求，并在时空信息服务、业务协同等领域提供了共性能力的支持。
        包括模型渲染、孪生建模、认知识别分析以及仿真模拟等功能。同时，我们也汇聚了来自政务数据、物联网、互联网以及其他社会资源的数据，为数字孪生应用提供了充足的数据支持。
        通过这些努力，我们致力于打造一个强大而灵活的数字孪生平台，以应对城市管理、服务优化等各种场景下的需求，为城市发展和居民生活带来更多的智慧和便利。`,
    image: "chengshi.png",
    module: intelligentCity,
  },
  {
    name: "安全应急",
    description: `我们致力于打造一张图，构建微观交通仿真的数字孪生系统。这个系统能够直观展现中观和微观的交通流运行过程，为交通仿真决策算法的研发提供支持。我们的目标是解决交通拥堵等难题，为管理者提供可靠的决策依据。
        此外，我们还将实现停车场的实时信息化、数字化、可视化升级。通过平台化管理，我们实现了停车场多类型系统信息之间的互联互通，降低了管理压力和运维成本。我们的系统不仅带来了更便捷、高效、高水平的管理能力，也提升了形象。
        我们利用监控视频数据提取的结构化数据，模拟复现实时交通流。这使得管理者能够实现路口交通态势监控，为交通业务研判提供有力支持。`,
    image: "yingji.png",
    module: safetyEmergency,
  },
  {
    name: "智慧电力",
    description: `数字孪生建筑BIM平台是一个强大的工具，具备更全面、更精准、更快速的信息处理能力。它可以利用高精度的数字模型来准确描述现实世界中的建筑物，实现全生命周期的数字管理与模拟。通过与物联网的结合，平台能够将现实世界中采集的真实信息反映到数字模型中，实现模型的持续更新。
        在数字空间内，数字孪生建筑BIM平台可以利用模型和信息进行预测性的仿真分析，为用户提供可视化的管理方案和智能对接服务。这种平台能够满足各行各业的普遍性需求，为用户提供更高效、更智能的解决方案。`,
    image: "dianli.png",
    // 使用picModule时，替换name 和 image 更换图片
    module: intelligentElectricity,
  },
  {
    name: "智慧地产",
    description: `采用宏观三维可视化和微观精细化管理手段，实现了对设备、环境和事件的高效管理与监测。项目建成后，可以助力客户实现“无人值班、少人值守”的运维管理模式，促进低碳、绿色、安全的智慧风电场管理。通过这一智能化系统，管理人员可以更好地掌握场站的实时状态和运行情况。`,
    video: "",
    image: "dichan.png",
    // 使用videoModule时，替换name 和 video 更换视频
    module: intelligentRealestate,
  },
  {
    name: "智慧矿山",
    description: `致力于构建流域范围的空间数据集，形成多维多尺度的数据底板。通过整合现有信息系统的工程全要素感知数据、业务管理数据以及其他相关数据，建立数字孪生平台，如小浪底，为防洪排涝、工程安全、泥沙管理、发电等业务提供精细化管理、趋势预测和科学决策的支持。这为服务黄河流域的生态保护和国家高质量发展提供了战略性保障。
        该项目让客户能够全方位管控和运维枢纽工程的设备、安防和资产，从而提升管理效率、降低运维成本。通过实现“感知有系统、决策有支撑、管理有手段、运营有成效”的高效管理网络，持续产生社会、环境和经济效益。`,
    video: "",
    image: "kuangshan.png",
    // 使用videoModule时，替换name 和 video 更换视频
    module: intelligentDiggings,
  },
  {
    name: "智慧水利",
    description: `旨在为展馆、博物馆、企业展示厅等场所提供先进的数字化展示和互动体验。该解决方案融合了最新的科技成果，包括数字展示设备、智能交互系统、远程监控与管理平台等多个方面，以实现对展览内容的生动展示和观众互动。智慧展厅采用先进的数字展示设备，如高清显示屏、LED显示墙等，以高清、高亮的形式呈现展品、文物等内容，为观众提供视觉上的震撼和享受。
        配备智能交互系统，包括触摸屏、手势识别、语音交互等技术，使观众能够与展品进行互动，了解更多相关信息，增强参观体验的趣味性和参与感。还提供远程监控与管理平台，展馆管理者可以通过该平台实时监测展厅内的展示情况、观众流量等信息，灵活调整展示内容和布局，提高展览效果和管理效率。`,
    video: "",
    image: "shuili.png",
    // 使用videoModule时，替换name 和 video 更换视频
    module: intelligentWaterConservancy,
  },
  {
    name: "智慧文旅",
    description: `旨在为展馆、博物馆、企业展示厅等场所提供先进的数字化展示和互动体验。该解决方案融合了最新的科技成果，包括数字展示设备、智能交互系统、远程监控与管理平台等多个方面，以实现对展览内容的生动展示和观众互动。智慧展厅采用先进的数字展示设备，如高清显示屏、LED显示墙等，以高清、高亮的形式呈现展品、文物等内容，为观众提供视觉上的震撼和享受。
        配备智能交互系统，包括触摸屏、手势识别、语音交互等技术，使观众能够与展品进行互动，了解更多相关信息，增强参观体验的趣味性和参与感。还提供远程监控与管理平台，展馆管理者可以通过该平台实时监测展厅内的展示情况、观众流量等信息，灵活调整展示内容和布局，提高展览效果和管理效率。`,
    video: "",
    image: "wenlv.png",
    // 使用videoModule时，替换name 和 video 更换视频
    module: intelligentTravel,
  },
  {
    name: "智慧医疗",
    description: `旨在为展馆、博物馆、企业展示厅等场所提供先进的数字化展示和互动体验。该解决方案融合了最新的科技成果，包括数字展示设备、智能交互系统、远程监控与管理平台等多个方面，以实现对展览内容的生动展示和观众互动。智慧展厅采用先进的数字展示设备，如高清显示屏、LED显示墙等，以高清、高亮的形式呈现展品、文物等内容，为观众提供视觉上的震撼和享受。
        配备智能交互系统，包括触摸屏、手势识别、语音交互等技术，使观众能够与展品进行互动，了解更多相关信息，增强参观体验的趣味性和参与感。还提供远程监控与管理平台，展馆管理者可以通过该平台实时监测展厅内的展示情况、观众流量等信息，灵活调整展示内容和布局，提高展览效果和管理效率。`,
    video: "",
    image: "yiliao.png",
    // 使用videoModule时，替换name 和 video 更换视频
    module: intelligentMedical,
  },
];
