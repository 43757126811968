import React from "react";

function picModule(item) {
    return (
        <div
            className="overflow-hidden bg-slate-950 rounded text-white p-4"
            key={item.image}
        >
            <div className="program-content flex flex-col md:flex-row">
                <img src={item.image} alt={item.name}/>
            </div>
        </div>
    )
}

export default picModule;
