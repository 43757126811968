import React from "react";
import picModule from "../modules/picModule";
import videoModule from "../modules/videoModule";
import textModule from "../modules/textModule";
import headerModule from "../modules/headerModule";

function intelligentCity() {
  return (
    <div className="overflow-hidden bg-slate-950 rounded text-white p-4" key={1}>
      <div className="text-center text-xl md:text-2xl lg:text-5xl font-bold mb-12 mt-8">智慧地产</div>
      {textModule({
        name: "方案价值",
        subName: "PRODUCT VALUE",
        subItem: [
          {
            name: "智能管理，优化资源",
            content: "利用物联网和大数据技术，实现对建筑和设施的智能管理，优化资源配置，减少能源浪费，提高物业管理的整体效率。",
            image: "./img/scys01.png",
          },
          {
            name: "精准分析，提高收益",
            content: "通过数据分析和人工智能技术，精准预测市场趋势和客户需求，制定更具针对性的营销策略和租赁方案，提高地产项目的收益率。",
            image: "./img/scys11.png",
          },
          {
            name: "互动平台，提升体验",
            content: "构建智能互动平台，提供虚拟看房、在线预约等服务，提升用户体验，同时收集用户反馈，持续改进服务质量，增强客户满意度。",
            image: "./img/scys12.png",
          },
        ],
      })}
      {headerModule("业务架构", "ARCHITECTURE")}
      {picModule({ image: "./img/internal-programs/jiagou_dichan.png", name: "云原生" })}
      {headerModule("细分领域", "SUBDIVISION")}
      {picModule({ image: "./img/internal-programs/zhihuidichan.png", name: "云原生" })}
      {headerModule("典型案例", "TYPICAL CASE")}
      {videoModule(
        {
          name: "杭州智慧工厂<br/>监控管理平台",
          description: `项目运用物联网、云计算和大数据技术，构建了一个集成生产调度、设备管理、质量控制和能源管理等功能的智能平台。系统能够实时采集和分析生产数据，帮助管理者做出科学决策。通过可视化界面，工厂的各项运营数据一目了然，从而提高生产过程的透明度和可控性。同时，平台具备智能预警和故障诊断功能，及时发现和处理生产中的异常情况，减少停机时间和维护成本。该项目不仅显著提升了工厂的运营效率，还促进了传统制造业向智能制造的转型升级。`,
          video: "https://obs.talsys.cn/demo_video/%E6%99%BA%E6%85%A7%E5%9C%B0%E4%BA%A7_%E6%9D%AD%E5%B7%9E%E6%99%BA%E6%85%A7%E5%B7%A5%E5%8E%82.mp4",
        },
        true
      )}
      {videoModule(
        {
          name: "温州龙湾国际机场<br/>机场管理平台",
          description: `平台整合了航班调度、旅客服务、安全管理、物流运输等多个模块，通过物联网、大数据、人工智能等先进技术，实现了对机场各项运营数据的实时监控和智能分析。平台应用数字孪生技术，构建了机场的三维虚拟模型，提供直观的可视化管理工具，帮助管理人员快速做出决策和应对突发情况。通过对数据的深度挖掘和分析，平台能够优化资源配置，提升服务质量，减少运营成本。温州龙湾国际机场管理平台的建设，不仅提高了机场的管理效率和安全性，还为旅客提供了更加便捷、舒适的出行体验，推动了智慧机场的发展。`,
          video: "https://obs.talsys.cn/demo_video/%E6%99%BA%E6%85%A7%E5%9C%B0%E4%BA%A7_%E6%B8%A9%E5%B7%9E%E9%BE%99%E6%B9%BE%E5%9B%BD%E9%99%85%E6%9C%BA%E5%9C%BA.mp4",
        },
        false
      )}
      {videoModule(
        {
          name: "雄安新区规划建设<br/>建工管控平台",
          description: `该平台集成了工程进度管理、质量监控、安全管理、成本控制等功能，通过物联网技术和大数据分析，实现了对建筑工地的实时监控和智能化管理。平台采用数字孪生技术，对施工现场进行三维建模，实现虚拟与现实的实时互动，帮助管理人员更直观地了解现场情况，及时处理突发事件。通过智能化的设备和数据分析，平台能够优化资源配置，降低成本，提高工程质量和效率。智慧建工管控平台的应用，为智慧地产的发展提供了坚实的技术支撑，助力企业实现精细化管理和可持续发展。`,
          video: "https://obs.talsys.cn/demo_video/%E6%99%BA%E6%85%A7%E5%9C%B0%E4%BA%A7_%E5%BB%BA%E5%B7%A5%E4%B8%80%E4%BD%93%E5%8C%96.mp4",
        },
        true
      )}
      {videoModule(
        {
          name: "雄安智慧工地<br/>工地管理平台",
          description: `通过搭建智能管理平台，集成了人员、设备、材料等多方面数据，实现实时监控和管理。平台采用物联网、大数据分析和人工智能技术，能够实时监测施工进度、环境参数、设备运行状态等，并提供数据分析和预警功能。通过3D可视化技术，管理人员可以直观了解工地全貌和各项施工细节，及时发现和解决问题。此外，平台还支持多部门协同工作，提升沟通效率和应急响应能力。项目的实施有效提高了工地管理的精细化程度，降低了管理成本，确保施工安全和质量，为智慧工地建设树立了标杆。`,
          video: "https://obs.talsys.cn/demo_video/%E6%99%BA%E6%85%A7%E5%9C%B0%E4%BA%A7_%E6%99%BA%E6%85%A7%E5%B7%A5%E5%9C%B0.mp4",
        },
        false
      )}
    </div>
  );
}

export default intelligentCity;
