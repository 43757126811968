import React from "react";
import picModule from "../modules/picModule";
import videoModule from "../modules/videoModule";
import textModule from "../modules/textModule";
import headerModule from "../modules/headerModule";

function intelligentCity() {
  return (
    <div className="overflow-hidden bg-slate-950 rounded text-white p-4" key={1}>
      <div className="text-center text-xl md:text-2xl lg:text-5xl font-bold mb-12 mt-8">智慧文旅</div>
      {textModule({
        name: "方案价值",
        subName: "PRODUCT VALUE",
        subItem: [
          {
            name: "数字导览，提升体验",
            content: "通过AR/VR技术和智能导览设备，为游客提供沉浸式体验，实时讲解景区历史和文化，提高游客的互动感和满意度。",
            image: "./img/scys01.png",
          },
          {
            name: "数据分析，优化服务",
            content: "利用大数据技术分析游客行为和偏好，优化景区服务和资源配置，提升运营效率，提供更个性化和高效的服务。",
            image: "./img/scys11.png",
          },
          {
            name: "智能营销，增加收益",
            content: "通过精准的数字营销策略和个性化推荐系统，吸引更多游客，增加门票和衍生品销售，提高景区整体收益。",
            image: "./img/scys12.png",
          },
        ],
      })}
      {headerModule("业务架构", "ARCHITECTURE")}
      {picModule({ image: "./img/internal-programs/jiagou_wenlv.png", name: "云原生" })}
      {headerModule("细分领域", "SUBDIVISION")}
      {picModule({ image: "./img/internal-programs/zhihuiwenlv.png", name: "云原生" })}
      {headerModule("典型案例", "TYPICAL CASE")}

      {videoModule(
        {
          name: "梨木台风景区<br/>可视化平台",
          description: `利用可视化技术提升了游客体验和景区管理效率。通过建设高分辨率全景摄像头和传感器网络，实现了景区的实时监控和数据采集。智慧导览系统为游客提供了虚拟导游服务，结合增强现实技术，使游客能够通过移动设备获取景点的详细信息和推荐路线。大数据分析功能帮助管理人员了解游客流量、偏好和行为模式，优化资源配置和服务质量。紧急预警系统则通过分析实时数据，及时发现并处理潜在的安全隐患。该项目的实施不仅提升了游客的满意度，也提高了景区的运营管理水平，为其他旅游景区的智慧化建设提供了参考范例。`,
          video: "https://obs.talsys.cn/demo_video/%E6%99%BA%E6%85%A7%E6%96%87%E6%97%85_%E6%A2%A8%E6%9C%A8%E5%8F%B0%E9%A3%8E%E6%99%AF%E5%8C%BA.mp4",
        },
        true
      )}
      {videoModule(
        {
          name: "海南海花岛<br/>可视化平台",
          description: `通过先进的数字化技术和数据分析，实现对海花岛旅游资源的智能化管理和高效运行。该平台集成了物联网、大数据、人工智能等技术，提供景区客流量监控、设备状态监测、环境数据采集等多种功能。通过3D可视化界面，管理人员可以实时查看海花岛各区域的运行状态，及时发现和处理问题，提升管理效率。同时，平台还支持智能分析和预测，帮助制定更科学的运营策略，优化游客体验，提升景区服务质量。该项目的实施，不仅提升了海花岛的管理水平，还为游客提供了更安全、便捷的旅游环境，推动了智慧文旅的发展。`,
          video: "https://obs.talsys.cn/demo_video/%E6%99%BA%E6%85%A7%E6%96%87%E6%97%85_%E6%B5%B7%E8%8A%B1%E5%B2%9B.mp4",
        },
        false
      )}
      {videoModule(
        {
          name: "浙江度假酒店<br/>可视化平台",
          description: `致力于提升酒店管理和游客体验，通过构建一个集成的智能平台，实现度假酒店的全方位数字化管理。项目采用物联网、云计算和大数据技术，将酒店的预订、客房服务、餐饮管理、娱乐活动等模块整合到一个统一的可视化界面中。系统能够实时监测酒店运营状况，分析游客行为数据，提供个性化服务建议，从而提高客户满意度。同时，管理者可以通过平台直观地了解酒店的运营数据和资源利用情况，优化管理决策。通过智能预警功能，酒店能够及时应对各类突发状况，确保运营的顺畅和安全。`,
          video: "https://obs.talsys.cn/demo_video/%E6%99%BA%E6%85%A7%E6%96%87%E6%97%85_%E5%BA%A6%E5%81%87%E9%85%92%E5%BA%97%E5%8F%AF%E8%A7%86%E5%8C%96.mp4",
        },
        true
      )}
    </div>
  );
}

export default intelligentCity;
