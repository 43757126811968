import React, { useRef, useState } from "react";
import styled from "styled-components";
import { programsList } from "../../constants/programs";
import module1 from "./modules/module1";

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  background-color: #010116;
`;

const Container = styled.div`
  scroll-snap-align: center;
  width: 100%;
  padding-top: 100px;
  @media only screen and (max-width: 768px) {
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
  }
`;

const ProgramDescription = styled.div`
  @media only screen and (max-width: 768px) {
    margin-top: 1rem;
  }
`;

const SolutionsButtons = styled.div`
  // background: #1b1b1b;
  // padding-right: 2rem;
  display: flex;
  // align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  background-color: #182d3c;
  overflow: hidden;
  border-radius: 10px;
  transition: 0.5s;
  text-align: center;

  .SolutionsButton {
    // width: 6rem;
    // position: relative;
    font-size: 1rem;
    margin: 5px;
    // font-weight: bold;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    // padding: 1rem 3rem 1rem;
    flex-grow: 1;
    :hover {
      // background: rgba(0.1, 0.1, 0.1, 0.3);
      filter: drop-shadow(0 0 10px #fff) drop-shadow(0 0 20px #fff);
      // color: #409eff;
    }
  }

    .SolutionsButton:nth-child(1).checked ~ .underline {
      left: 0%;
    }
    .SolutionsButton:nth-child(2).checked ~ .underline {
      left: 12.5%;
    }
    .SolutionsButton:nth-child(3).checked ~ .underline {
      left: 25%;
    }
    .SolutionsButton:nth-child(4).checked ~ .underline {
      left: 37.5%;
    }
    .SolutionsButton:nth-child(5).checked ~ .underline {
      left: 50%;
    }
    .SolutionsButton:nth-child(6).checked ~ .underline {
      left: 62.5%;
    }
    .SolutionsButton:nth-child(7).checked ~ .underline {
      left: 75%;
    }
    .SolutionsButton:nth-child(8).checked ~ .underline {
      left: 87.5%;
    }

    .underline {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 12.5%;
      height: 15px;
      // background-color: red;
      transition: 0.5s;
    }
    .underline::after {
      content: "";
      position: absolute;
      left: 49.2%;
      bottom: 0%;
      transform: translateX(-50%);
      width: 50%;
      border-radius: 4px;
      height: 20%;
      background-color: #fff;
      filter: drop-shadow(0 0 10px #fff) drop-shadow(0 0 20px #fff) drop-shadow(0 0 30px #fff) drop-shadow(0 0 50px #fff);
      transition: 0.5s;
    }

  .checked {
    // background: rgba(0.1, 0.1, 0.1, 0.3);
    // color: #409eff;
    color: #fff;

    filter: drop-shadow(0 0 10px #fff) drop-shadow(0 0 20px #fff);
    transition: 0.5s;
  }

 
 
`;

function Programs() {
  let [chooseId, setChooseId] = useState(0);

  function chooseItem(evt) {
    console.log(123);
  }
  return (
    <>
      <div>
        <Container>
          <section className="py-0 px-2 mx-auto bg-blue">
            
            <div className="px-4 mx-auto sm:px-6 lg:px-8" style={{ maxWidth: "90rem" }}>
              <div className="max-w-6xl mx-auto text-center">
                {/*<h2 className="text-3xl font-bold leading-tight text-transparent bg-clip-text  bg-gradient-to-r from-teal-400 to-yellow-200 sm:text-4xl lg:text-5xl">*/}
                {/*   解决方案*/}
                {/*</h2>*/}
                {/*<br></br>*/}
                {/* <p className="max-w-7xl mx-auto my-10 text-justify text-xl font-bold leading-tight text-transparent bg-clip-text  bg-gradient-to-r from-amber-500 to-pink-500">为您提供了全面的可视化技术解决方案，涵盖了各行各业的广泛应用。通过结合先进的可视化技术和行业专业知识，我们致力于为客户提供创新的解决方案，满足项目的具体需求和挑战。无论是数创孪生智慧决策、建筑BIM施工监测、城市CIM智能应用、流程或数值仿真、多行业产品营销展示、党建宣传云展厅还是虚拟现实应用，我们都能为您量身定制符合您需求的解决方案。我们的解决方案不仅注重技术创新和视觉效果，更重视与客户的紧密合作和需求沟通，确保每一个项目都能达到最佳的效果和用户体验。</p> */}
              </div>

              <div className="grid grid-cols-1 gap-6 mt-12 lg:mt-16 xl:gap-10 sm:grid-cols-1 lg:grid-cols-1">
              <SolutionsButtons className="mx-auto w-full">
              <div className="position relative top-0 left-0 justify-between w-full" style={{ display: "inherit", flexWrap: "no-wrap" }}>
                {programsList.map((item, idx) => {
                  return (
                    <div
                      key={idx}
                      className={"SolutionsButton " + (chooseId === idx ? "checked" : "")}
                      onClick={() => {
                        setChooseId(idx);
                      }}>
                      <div className="flex">
                        <img alt="gallery" className="w-[20px] h-[20px] lg:w-[50px] lg:h-[50px] object-cover object-center block mb-2" src={chooseId === idx ? "./img/internal-programs/" + "white_" + item.image : "./img/internal-programs/" + item.image}></img>
                      </div>
                      <div className={"text-[8px] lg:text-[16px]" +  (chooseId === idx ? " text-[#fff]" : " text-[#5b5b5b]") }>
                        {item.name}
                      </div>
                    </div>
                  );
                })}
                <div className="underline"></div>
              </div>
            </SolutionsButtons>
                {programsList[chooseId].module(programsList[chooseId])}
                </div>
            </div>
          </section>
        </Container>
      </div>
    </>
  );
}

export default Programs;
