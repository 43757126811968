import React from "react";
import textModule from "./textModule";

function videoModule(item, leftRight) {
  function textContent() {
    return (
      <div className="program-description items-center text-lg p-4 font-extrabold md:w-1/2 md:h-auto md:text-sm lg:text-xl xl:text-3xl">
        <div
          style={{
            display: "flex",
            alignItems: "center", // 垂直居中（对于单行文本或行内元素）
            marginBottom: "0.5rem",
            padding: "0 .75rem",
            fontSize: "1.5rem",
            letterSpacing: "0.05em",
            fontWeight: 1000, // 注意：fontWeight通常是一个字符串，如'1000'应该为'900'（因为900是fontWeight的最大值）
          }}>
          <div
            style={{
              width: "4px",
              height: "64px",
              background: "linear-gradient(180deg, #3369ff, #2e98ff)",
              marginRight: "16px",
              flex: "0 0 auto",
            }}></div>
          <div dangerouslySetInnerHTML={{ __html: item.name }} />
        </div>
        <div className="md:text-xl p-3" style={{ color: "hsla(0,0%,100%,.7)", fontSize: "0.875rem", fontWeight: 500 }}>
          {item.description}
        </div>
      </div>
    );
  }

  function videoContent() {
    return (
      <div
        className="program-image flex items-center justify-center md:w-3/6 md:pr-4" // 添加 flex 和 items-center 类
        // 或者如果您不想使用 Tailwind，可以这样写：
        // style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <video src={item.video} controls>
          您的浏览器不支持 video 标签。
        </video>
      </div>
    );
  }
  function left() {
    return [videoContent(), textContent()];
  }

  function right() {
    return [textContent(), videoContent()];
  }

  return (
    <div className="overflow-hidden bg-slate-950 rounded text-white p-4" key={item.video}>
      <div className="program-content flex flex-col md:flex-row">{leftRight ? left() : right()}</div>
    </div>
  );
}

export default videoModule;
