import React from "react";
import picModule from "../modules/picModule";
import videoModule from "../modules/videoModule";
import textModule from "../modules/textModule";
import headerModule from "../modules/headerModule";

function intelligentCity() {
  return (
    <div className="overflow-hidden bg-slate-950 rounded text-white p-4" key={1}>
      <div className="text-center text-xl md:text-2xl lg:text-5xl font-bold mb-12 mt-8">智慧医疗</div>
      {textModule({
        name: "方案价值",
        subName: "PRODUCT VALUE",
        subItem: [
          {
            name: "数据共享，协同诊疗",
            content: "通过构建统一的医疗信息平台，实现患者病历、检查结果等数据的实时共享，促进不同医疗机构之间的协同诊疗，提高诊疗效率和准确性。",
            image: "./img/scys01.png",
          },
          {
            name: "精准医疗，提升疗效",
            content: "利用基因测序、大数据分析等先进技术，根据患者的个体差异制定个性化治疗方案，提高治疗的针对性和有效性，提升整体疗效。",
            image: "./img/scys11.png",
          },
          {
            name: "智能监测，预防疾病",
            content: "通过可穿戴设备和远程监测系统，实时监测患者健康指标，早期发现潜在健康问题，提供及时干预和预防措施，减少疾病发生率。",
            image: "./img/scys12.png",
          },
        ],
      })}
      {headerModule("业务架构", "ARCHITECTURE")}
      {picModule({ image: "./img/internal-programs/jiagou_yiliao.png", name: "云原生" })}
      {headerModule("细分领域", "SUBDIVISION")}
      {picModule({ image: "./img/internal-programs/zhihuiyiliao.png", name: "云原生" })}
      {headerModule("典型案例", "TYPICAL CASE")}
      {videoModule(
        {
          name: "某市三甲医院<br/>综合管理平台",
          description: `通过物联网技术和大数据分析，平台能够实时监控医院设备的运行状态和患者的健康数据，及时发现并解决潜在问题。智能排队系统减少了患者的等待时间，个性化的健康管理服务提高了患者的满意度。该平台的实施不仅优化了医院的资源配置，也提升了医疗服务质量和管理水平，为其他医院的智慧化建设提供了可借鉴的成功经验。旨在提高医院的管理效率和患者的就医体验。该平台整合了电子病历、智能预约、远程医疗、药品管理和财务管理等多项功能，形成了一个全面的数字化医疗服务体系。`,
          video: "https://obs.talsys.cn/demo_video/%E6%99%BA%E6%85%A7%E5%8C%BB%E7%96%97_%E6%99%BA%E6%85%A7%E5%8C%BB%E9%99%A2%E7%AE%A1%E7%90%86%E5%B9%B3%E5%8F%B0.mp4",
        },
        true
      )}
      {videoModule(
        {
          name: "CT医疗<br/>在线管控系统",
          description: `通过集成先进的医疗影像技术、云计算和人工智能，建立了一个全面的在线管控平台。该系统可以实现CT影像的实时上传、存储和管理，并通过智能算法进行影像处理与分析，提高诊断的准确性和效率。医生可以通过平台远程访问和查看CT影像，进行诊断和治疗方案的制定，同时，系统支持多方会诊功能，便于专家协同工作。此外，平台还具备数据挖掘和统计分析功能，帮助医疗机构优化资源配置和临床决策。该项目不仅提升了医疗服务的智能化和信息化水平，还大幅度提高了患者的就医体验和诊疗效率。`,
          video: "https://obs.talsys.cn/demo_video/%E6%99%BA%E6%85%A7%E5%8C%BB%E7%96%97_CT%E8%AE%BE%E5%A4%87.mp4",
        },
        false
      )}
      {videoModule(
        {
          name: "华西第二医院<br/>数字全景平台",
          description: `平台通过整合医院的各类信息系统，实现病患信息、医疗设备、诊疗过程等的全方位数字化管理和实时监控。利用大数据、物联网、人工智能等技术，平台可以对病患的诊疗数据进行智能分析，提供精准的诊疗建议，提高医疗服务的效率和质量。此外，平台还具备远程医疗功能，支持医生进行远程会诊和指导，方便患者获得高质量的医疗服务。项目的实施不仅优化了医院的管理流程，提升了医疗服务水平，还为患者提供了更加便捷和高效的医疗体验。通过该平台，医院能够更好地实现资源整合和优化配置，`,
          video: "https://obs.talsys.cn/demo_video/%E6%99%BA%E6%85%A7%E5%8C%BB%E7%96%97_%E6%95%B0%E5%AD%97%E5%85%A8%E6%99%AF%E5%8C%BB%E9%99%A2.mp4",
        },
        true
      )}
    </div>
  );
}

export default intelligentCity;
