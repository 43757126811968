export const images = [
  { src: "./img/2.jpg", alt: "Image 1" },
  { src: "./img/3.jpeg", alt: "Image 2" },
  { src: "./img/4.jpg", alt: "Image 3" },
  { src: "./img/5.jpg", alt: "Image 4" },
];

export const Galleryimages = [
  { src: "./img/su7.jpg", alt: "Image 1" },
  { src: "./img/4.jpg", alt: "Image 2" },
  { src: "./img/6.jpeg", alt: "Image 3" },
  { src: "./img/3.jpg", alt: "Image 4" },
  { src: "./img/2.jpg", alt: "Image 5" },
  { src: "./img/5.jpg", alt: "Image 6" },
];

export const testimonials = [
  {
    text: `
    1、城市生命线：管网监测、设备管理、爆管分析、管网泄漏 <br/> 
    2、安全生产：危化品监测、工程建设安全监测、水库垮坝风险监测 <br/> 
    3、公共安全：大型建筑/客流、重点车辆风险监测、预案模拟 <br/> 
    4、自然灾害：地震/洪涝/台风、等灾害仿真模拟、灾害预警 <br/> 
    `,
    name: "安全应急",
    image: "./img/scys10.png",
    position: "Organization Head 2022-23",
  },
  {
    text: `
    1、数据集成：系统数据接入、物料批次追踪、高级构件树、模型批注审查 <br/> 
    2、物联接入：三维拟真物联、批量视频接入、日常安全巡检、综合管网布线 <br/> 
    3、仿真模拟：构件动画、工程进度模拟、分屏对比、自动抽屉分层 <br/> 
    4、空间计算：模型版本对比、构件操作、属性计算、进度对比 <br/> 
    `,
    name: "智慧建筑",
    image: "./img/scys10.png",
    position: "Organization Head 2022-23",
  },
  {
    text: `
    1、智慧水厂/泵站：工艺监测、工艺仿真、设备管理、智能调度、虚拟巡检、远程值守 <br/> 
    2、城市水环境：综合水质监测、排水户管理、管网溯源、水质扩散预演、网格化管理 <br/> 
    3、城市内涝：资产管理、综合预报、实时预警、仿真预演、应急预案、一体化调度 <br/> 
    `,
    name: "智慧水务",
    image: "./img/scys10.png",
    position: "Organization Head 2022-23",
  },
  {
    text: `
    1、数字孪生流域：流域一张图、水资源管理、防洪排涝四预、幸福河湖管理、生态环境仿真 <br/> 
    2、数字孪生工程：设施设备管控、工程安全监测、工艺流程仿真、维养巡检管理、生产运营管理 <br/> 
    3、数字孪生灌区：灌区一张图、供需水预报、水资源配置、供用水调度、水旱灾害防御 <br/> 
    4、数字李生水网：联合调度决策、安全运行监视、航道船闸管理、日常业务管理、应急事件处置 <br/> 
    `,
    name: "智慧水利",
    image: "./img/scys10.png",
    position: "Organization Head 2022-23",
  },
  {
    text: `
    1、会议会展中心：场馆态势、排期管理、布展规划、人流热力分析、双碳管理 <br/> 
    2、文体场馆：观众分析、赛事运营、安防管理、应急演练 <br/> 
    3、文博场馆：文物云展览、文物修复、数字艺术品还原 <br/> 
    `,
    name: "智慧场馆",
    image: "./img/scys10.png",
    position: "Organization Head 2022-23",
  },
  {
    text: `
    1、自然景区：运营态势、安全监管、游客分析、环境管理 <br/> 
    2、城市公园：植被态势、公建铺设、卫生管理、停车管理 <br/> 
    3、文旅小镇：商业布局、产业分析、区位优势、综合管理 <br/> 
    4、线上旅游：景观展示、文创宣传、多人携游、剧情互动 <br/> 
    `,
    name: "智慧文旅",
    image: "./img/scys10.png",
    position: "Organization Head 2022-23",
  },
  {
    text: `
    1、产业振兴：产业态势、产业态势、乡村旅游、乡村治理一盘棋 <br/> 
    2、组织振兴：基层党建、村务管理、乡村治理 <br/> 
    3、生态振兴：环境治理、生态宜居、绿色能源 <br/> 
    4、文化振兴：文明乡村、非遗传承、村史馆建设 <br/> 
    5、人才振兴：人才管理、人才引进、技能培训 <br/> 
    `,
    name: "数字乡村",
    image: "./img/scys10.png",
    position: "Organization Head 2022-23",
  },
  {
    text: `
    1、数字乡村：乡村治理一盘棋 <br/> 
    2、安全应急：城市生命线、公共安全、生产安全、自然灾害 <br/> 
    3、城市体检：城市规划数据上图、实时监测定期评估、决策支撑 <br/> 
    4、城市招商：资产管理、产业经济、科技创新、招商引资 <br/> 
    5、城市更新：片区运营、社区嵌入式服务、可持续发展 <br/> 
    6、智慧公安：警务大脑、治安防控、情指勤舆一体化 <br/> 
    `,
    name: "智慧城市",
    image: "./img/scys10.png",
    position: "Organization Head 2022-23",
  },
  {
    text: `
    1、警务大脑：统一空间数字底座、多源数据融合集成、实时监管综合研判、仿真分析智能决策 <br/> 
    2、公安视频图像：综合应用、视频资源整合、综合态势监测、事件研判分析、实时联动指挥 <br/> 
    3、社会治安：防控体系、全域圈层查控、全局单元防控、全量要素管控 <br/> 
    4、情指勤舆：一体化、情报收集、态势研判、信息支撑、指挥调度、辅助决策 <br/> 
    5、智慧派出所：数字勤务中心、辖区智控平台、社区管控平台、视频智能分析平台 <br/> 
    `,
    name: "智慧公安",
    image: "./img/scys10.png",
    position: "Organization Head 2022-23",
  },
];

export const members = [
  {
    image: "./img/scys01.png",
    name: "多源异构数据融合",
    position: "结合了超过30个静态模型数据和1000个动态协议数据，实现了对真实世界的全方位复制和仿真、虚拟环境更加真实和丰富",
    github: "",
    linkedin: "",
    instagram: "",
    twitter: "",
  },

  {
    image: "./img/scys02.png",
    name: "虚实互动",
    position: "30+全要素复刻真实世界，实时感知真实世界信息，同步呈现，反向互动，信息传递，仿真实验，场景映射，优化运行",
    github: "",
    linkedin: "",
    instagram: "",
    twitter: "",
  },

  {
    image: "./img/scys03.png",
    name: "全栈自主可控",
    position: "自研引擎，支持国产操作系统、芯片，深度优化和定制化开发，实现了与国产生态系统的完美融合，自主定制和灵活扩展",
    github: "",
    linkedin: "",
    instagram: "",
    twitter: "",
  },

  {
    image: "./img/scys04.png",
    name: "全面支持多云部署",
    position: "全方位支持公有云、私有云和混合云部署，结合像素流传输技术，确保数据传输安全，实现高效的云端数据处理和分发",
    github: "",
    linkedin: "",
    instagram: "",
    twitter: "",
  },

  {
    image: "./img/scys05.png",
    name: "多终端交互",
    position: "允许用户在不同设备之间无缝切换和交互，提供一致的用户体验，适用于PC、手机、Pad、指挥中心大屏、AR、VR、XR",
    github: "",
    linkedin: "",
    instagram: "",
    twitter: "",
  },
  {
    image: "./img/scys06.png",
    name: "仿真模拟",
    position: "基于计算机技术的强大工具，可以模拟复杂系统的行为和性能，帮助理解系统运行规律、优化设计方案、预测结果",
    github: "",
    linkedin: "",
    instagram: "",
    twitter: "",
  },
];

export const allmembers = [
  {
    image: "https://obs.talsys.cn/zhuifengji_video/miniprogram/projectPic/aboutUs/Q.jpg",
    name: "Q",
    position: "创始人",
    team: "SC",
    github: "",
    linkedin: "",
    instagram: "",
    twitter: "",
  },

  {
    image: "https://obs.talsys.cn/zhuifengji_video/miniprogram/projectPic/aboutUs/CodingPotato.jpg",
    name: "Coding Potato",
    position: "创始人",
    team: "SC",
    github: "",
    linkedin: "",
    instagram: "",
    twitter: "",
  },

  {
    image: "https://obs.talsys.cn/zhuifengji_video/miniprogram/projectPic/aboutUs/Dylan.jpg",
    name: "Dylan",
    position: "创始人",
    team: "SC",
    github: "",
    linkedin: "",
    instagram: "",
    twitter: "",
  },

  {
    image: "https://obs.talsys.cn/zhuifengji_video/miniprogram/projectPic/aboutUs/Asta.jpg",
    name: "Asta",
    position: "创始人",
    team: "SC",
    github: "",
    linkedin: "",
    instagram: "",
    twitter: "",
  },

  {
    image: "https://obs.talsys.cn/zhuifengji_video/miniprogram/projectPic/aboutUs/AmazingGuo.jpg",
    name: "AmazingGuo",
    position: "创始人",
    team: "SC",
    github: "",
    linkedin: "",
    instagram: "",
    twitter: "",
  },

  {
    image: "https://obs.talsys.cn/zhuifengji_video/miniprogram/projectPic/aboutUs/Net.jpg",
    name: "Net",
    position: "3D模型师",
    team: "SC",
    github: "",
    linkedin: "",
    instagram: "",
    twitter: "",
  },

  {
    image: "https://obs.talsys.cn/zhuifengji_video/miniprogram/projectPic/aboutUs/Ramon.jpg",
    name: "Ramon",
    position: "全栈工程师",
    team: "SC",
    github: "",
    linkedin: "",
    instagram: "",
    twitter: "",
  },

  {
    image: "https://obs.talsys.cn/zhuifengji_video/miniprogram/projectPic/aboutUs/Takumi.jpg",
    name: "Takumi",
    position: "全栈工程师",
    team: "SC",
    github: "",
    linkedin: "",
    instagram: "",
    twitter: "",
  },

  {
    image: "https://obs.talsys.cn/zhuifengji_video/miniprogram/projectPic/aboutUs/Potato.jpg",
    name: "Potato",
    position: "前端工程师",
    team: "SC",
    github: "",
    linkedin: "",
    instagram: "",
    twitter: "",
  },

  {
    image: "https://obs.talsys.cn/zhuifengji_video/miniprogram/projectPic/aboutUs/Potato.jpg",
    name: "Potato",
    position: "前端工程师",
    team: "SC",
    github: "",
    linkedin: "",
    instagram: "",
    twitter: "",
  },

  {
    image: "https://obs.talsys.cn/zhuifengji_video/miniprogram/projectPic/aboutUs/Shmily.jpg",
    name: "Shmily",
    position: "运营工程师",
    team: "SC",
    github: "",
    linkedin: "",
    instagram: "",
    twitter: "",
  },

  {
    image: "https://obs.talsys.cn/zhuifengji_video/miniprogram/projectPic/aboutUs/Joe.jpg",
    name: "Joe",
    position: "运营工程师",
    team: "SC",
    github: "",
    linkedin: "",
    instagram: "",
    twitter: "",
  },

  {
    image: "https://obs.talsys.cn/zhuifengji_video/miniprogram/projectPic/aboutUs/Tina.jpg",
    name: "Tina",
    position: "前端工程师",
    team: "SC",
    github: "",
    linkedin: "",
    instagram: "",
    twitter: "",
  },

  {
    image: "https://obs.talsys.cn/zhuifengji_video/miniprogram/projectPic/aboutUs/Snow.jpg",
    name: "Snow",
    position: "园林景观设计师",
    team: "SC",
    github: "",
    linkedin: "",
    instagram: "",
    twitter: "",
  },

  {
    image: "https://obs.talsys.cn/zhuifengji_video/miniprogram/projectPic/aboutUs/Titans.jpg",
    name: "Titans",
    position: "运营工程师",
    team: "SC",
    github: "",
    linkedin: "",
    instagram: "",
    twitter: "",
  },

  {
    image: "https://obs.talsys.cn/zhuifengji_video/miniprogram/projectPic/aboutUs/Shine.jpg",
    name: "Shine",
    position: "前端工程师",
    team: "SC",
    github: "",
    linkedin: "",
    instagram: "",
    twitter: "",
  },

  {
    image: "https://obs.talsys.cn/zhuifengji_video/miniprogram/projectPic/aboutUs/Ahri.jpg",
    name: "Ahri",
    position: "UI设计师",
    team: "SC",
    github: "",
    linkedin: "",
    instagram: "",
    twitter: "",
  },

  {
    image: "https://obs.talsys.cn/zhuifengji_video/miniprogram/projectPic/aboutUs/Glint.jpg",
    name: "Glint",
    position: "运营工程师",
    team: "SC",
    github: "",
    linkedin: "",
    instagram: "",
    twitter: "",
  },

  {
    image: "https://obs.talsys.cn/zhuifengji_video/miniprogram/projectPic/aboutUs/Black.jpg",
    name: "Black",
    position: "运营工程师",
    team: "SC",
    github: "",
    linkedin: "",
    instagram: "",
    twitter: "",
  },

  {
    image: "https://obs.talsys.cn/zhuifengji_video/miniprogram/projectPic/aboutUs/Austin.jpg",
    name: "Austin",
    position: "运营工程师",
    team: "SC",
    github: "",
    linkedin: "",
    instagram: "",
    twitter: "",
  },

  {
    image: "https://obs.talsys.cn/zhuifengji_video/miniprogram/projectPic/aboutUs/Raine.jpg",
    name: "Raine",
    position: "Unity3D美术",
    team: "SC",
    github: "",
    linkedin: "",
    instagram: "",
    twitter: "",
  },

  {
    image: "https://obs.talsys.cn/zhuifengji_video/miniprogram/projectPic/aboutUs/Martin.jpg",
    name: "Martin",
    position: "UE程序开发",
    team: "SC",
    github: "",
    linkedin: "",
    instagram: "",
    twitter: "",
  },
];
