import React from "react";
import picModule from "../modules/picModule";
import videoModule from "../modules/videoModule";
import textModule from "../modules/textModule";
import headerModule from "../modules/headerModule";

function intelligentCity() {
  return (
    <div className="overflow-hidden bg-slate-950 rounded text-white p-4" key={1}>
      <div className="text-center text-xl md:text-2xl lg:text-5xl font-bold mb-12 mt-8">智慧城市</div>
      {textModule({
        name: "方案价值",
        subName: "PRODUCT VALUE",
        subItem: [
          {
            name: "数据互通，智慧赋能",
            content: "统一的标准数据，推动CIM+应用的建设，覆盖智慧城市“规划、建设、管理、运营、服务”各环节，各系统之的数据互联。",
            image: "./img/scys01.png",
          },
          {
            name: "构建云架，提升效率",
            content: "构建开放的云原生服务架构，提供标准化的API和便捷的二次开发工具包，从而减少开发和运维成本，提升开发效率。",
            image: "./img/scys11.png",
          },
          {
            name: "持续创收，动态更新",
            content: "采用平台运营管理模式，实现数字资产的动态更新与迭代，支持条件或无条件的数据开放，确保平台的持续盈利。",
            image: "./img/scys12.png",
          },
        ],
      })}
      {headerModule("业务架构", "ARCHITECTURE")}
      {picModule({ image: "./img/internal-programs/jiagou_chengshi.png", name: "云原生" })}
      {headerModule("细分领域", "SUBDIVISION")}
      {picModule({ image: "./img/internal-programs/zhihuichengshi.png", name: "云原生" })}
      {headerModule("典型案例", "TYPICAL CASE")}
      {videoModule(
        {
          name: "青岛中德生态园<br/>BIM管理平台",
          description: `青岛中德生态园在规划建设中坚持数字城市与现实城市同步推进，重点打造BIM管理平台。该平台中的数字孪生模块，是将数字孪生技术与CIM（城市信息模型）相结合的重要探索。通过数字孪生技术，可以展示从现状（BIM0）、总体规划（BIM1）、控制性详细规划（BIM2）、设计方案（BIM3）、施工过程（BIM4）到竣工验收（BIM5）的城市全生命周期风貌。该平台实现了全程管控和智能协同，构建了一个一体化的城市规划建设管理数字平台。`,
          video: "https://obs.talsys.cn/demo_video/%E6%99%BA%E6%85%A7%E5%9F%8E%E5%B8%82_%E9%9D%92%E5%B2%9B%E4%B8%AD%E5%BE%B7%E7%94%9F%E6%80%81%E5%9B%AD.mp4",
        },
        true
      )}
      {videoModule(
        {
          name: "数字杨浦<br/>CIM平台",
          description: `数字杨浦聚焦“善治、惠民、兴业”，核心在于构建和使用城市运行管理中心，打造统一的数字孪生底座。通过增强数据应用和能力支持，我们在城市规划和招商等领域建设了一系列应用场景，实现了空间、设备、数据、事件、建管和产业六大协同。为了实现“生态宜人、生活宜居、生产宜业”的总方向，以城市数字孪生平台为基础，依托智慧物联、数据治理和人工智能平台的数据支持，打造整体业务枢纽。满足数字孪生的各种场景需求，在时空信息服务和业务协同等场景中提供共性能力。`,
          video: "https://obs.talsys.cn/demo_video/%E6%99%BA%E6%85%A7%E5%9F%8E%E5%B8%82_%E6%95%B0%E5%AD%97%E6%9D%A8%E6%B5%A6%E8%A7%86%E9%A2%91.mp4",
        },
        false
      )}
    </div>
  );
}

export default intelligentCity;
