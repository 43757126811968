import styled from "styled-components";
import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Squash as Hamburger } from "hamburger-react";
const NavbarContainer = styled.nav`
  width: 100%;
  height: fit-content;
  background-color: transparent;
  display: flex;
  z-index: 99999999;
  flex-direction: column;
  align-items: center;
  position: fixed;
  transition: all 3s ease-in-out;
  @media (min-width: 900px) {
    height: 90px;
  }
`;

const LeftContainer = styled.div`
  flex: 70%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  backdrop-filter: blur(8px);
  transition: backdrop-filter 0.5s ease;
  z-index: 999;
  box-shadow: inset 0px -1px #1d2d44;
  @media (max-width: 900px) {
    flex: 30%;
  }
`;

const NavbarInnerContainer = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
`;

const NavbarLinkContainer = styled.div`
  display: flex;
`;

const NavLinks = styled.div`
  display: flex;
  gap: 120px;
`;

const NavbarLink = styled(Link)`
  color: white;
  font-size: 1rem;
  font-weight: 600;
  text-decoration: none;
  transition: 200ms ease-in-out;
  padding: 0.5rem;
  border-radius: 10px 0 10px 0;
  @media (max-width: 900px) {
    display: none;
  }
  :hover {
    // color: deepskyblue;
    background-color: rgba(255, 255, 255, 0.3);
  }
  &.active {
    background-color: rgba(255, 255, 255, 0.2);
  }
  &.active:hover {
    background-color: rgba(255, 255, 255, 0.3);
  }
`;

const NavbarLogo = styled(Link)`
  color: white;
  font-size: 20px;
  text-decoration: none;
`;

const NavbarLinkExtended = styled(Link)`
  color: white;
  font-size: 20px;
  text-decoration: none;
  margin: 10px;
  :hover {
    color: #3a60cf;
    font-weight: bold;
  }
`;

const Logo = styled.img`
  margin: 8px;
  max-width: 180px;
  height: 60px;
  align-self: center;
`;

const OpenLinksButton = styled.button`
  padding-top: 5px;
  cursor: pointer;
  @media (min-width: 900px) {
    display: none;
    align-self: center;
  }
`;

const NavbarExtendedContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
  gap: 10px;
  backdrop-filter: blur(20px);

  @media (min-width: 900px) {
    display: none;
    align-items: center;
  }
`;

const Button = styled.button`
  width: 100%;
  padding: 10px;
  background-color: #1d28f2;
  color: white;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: 200ms ease-in-out;
  @media (max-width: 900px) {
    margin: 17px 10px 10px 5px;
    ${"" /* display: none; */}
  }
  :hover {
    background-color: #138af2;
  }
`;

function Navbar() {
  const [isOpen, setOpen] = useState(false);
  const [activeNavLink, setActiveNavLink] = useState(null);

  const location = useLocation();

  React.useEffect(() => {
    setOpen(false);
    setActiveNavLink(location.pathname);
  }, [location]);

  let navigate = useNavigate();

  const handleClick = () => {
    navigate("/");
    // 注意：由于路由跳转是异步的，所以不能直接在这里进行滚动
  };

  function handleScroll() {
    var totalHeight = document.body.scrollHeight;
    console.log("aaaaaaaaa");
    // window.scrollTo(0, totalHeight);
    window.scroll({
      top: totalHeight,
      left: 0,
      behavior: "smooth",
    });
  }

  function scrollToContact() {
    if (location.pathname !== "/") {
      handleClick();
      // console.log(document.getElementById("index-contact-us"));
      setTimeout(() => {
        if (document.getElementById("index-contact-us") !== null) {
          handleScroll();
          // window.scrollTo(0, 10000);
          // document.getElementById("index-contact-us").scrollIntoView({ behavior: "smooth" ,block:'end'})
        }
      }, 100);
    } else {
      document.getElementById("index-contact-us").scrollIntoView({ behavior: "smooth" });
    }
  }

  return (
    <NavbarContainer isOpen={isOpen}>
      <NavbarInnerContainer>
        <LeftContainer>
          <NavbarLogo to="/">
            <Logo src="../.././img/logo5.png"></Logo>
          </NavbarLogo>
          <NavLinks>
            <NavbarLink to="/programs" className={activeNavLink === "/programs" ? "active" : ""}>
              <div>
                解决方案
                {activeNavLink === "/programs" && <hr className="border-3 rounded-full" />}
              </div>
            </NavbarLink>
            <NavbarLink
              to="/projects"
              // onClick={handlememu}
              className={activeNavLink === "/projects" ? "active " : ""}>
              <div>
                项目案例
                {activeNavLink === "/projects" && <hr className="border-3 rounded-full" />}
              </div>
            </NavbarLink>
            <NavbarLink to="/resources" className={activeNavLink === "/resources" ? "active" : ""}>
              <div>
                产品服务
                {activeNavLink === "/resources" && <hr className="border-3 rounded-full" />}
              </div>
            </NavbarLink>
            <NavbarLink to="/events" className={activeNavLink === "/events" ? "active" : ""}>
              <div>
                新闻动态
                {activeNavLink === "/events" && <hr className="border-3 rounded-full" />}
              </div>
            </NavbarLink>
            <NavbarLink to="/team" className={activeNavLink === "/team" ? "active" : ""}>
              <div>
                关于我们
                {activeNavLink === "/team" && <hr className="border-3 rounded-full" />}
              </div>
            </NavbarLink>
          </NavLinks>
          <NavbarLinkContainer>
            <Button onClick={scrollToContact}>联 系 我 们</Button>
            {/* {location.pathname} */}
            <OpenLinksButton>
              <Hamburger toggled={isOpen} toggle={setOpen} />
            </OpenLinksButton>
          </NavbarLinkContainer>
        </LeftContainer>
      </NavbarInnerContainer>
      {isOpen && (
        <NavbarExtendedContainer>
          <NavbarLinkExtended to="/">官网主页</NavbarLinkExtended>
          <NavbarLinkExtended to="/programs">解决方案</NavbarLinkExtended>
          <NavbarLinkExtended to="/projects">项目案例</NavbarLinkExtended>
          <NavbarLinkExtended to="/resources">产品服务</NavbarLinkExtended>
          <NavbarLinkExtended to="/events">新闻动态</NavbarLinkExtended>
          <NavbarLinkExtended to="/Team">关于我们</NavbarLinkExtended>
        </NavbarExtendedContainer>
      )}
    </NavbarContainer>
  );
}

export default Navbar;
