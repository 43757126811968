import React from "react";
import picModule from "../modules/picModule";
import videoModule from "../modules/videoModule";
import textModule from "../modules/textModule";
import headerModule from "../modules/headerModule";

function intelligentCity() {
  return (
    <div className="overflow-hidden bg-slate-950 rounded text-white p-4" key={1}>
      <div className="text-center text-xl md:text-2xl lg:text-5xl font-bold mb-12 mt-8">智慧水利</div>
      {textModule({
        name: "方案价值",
        subName: "PRODUCT VALUE",
        subItem: [
          {
            name: "实时监测，精准调度",
            content: "利用传感器和数据采集技术，实时获取水文数据，通过智能系统分析，动态调整水资源配置，优化防洪、灌溉和供水管理。",
            image: "./img/scys01.png",
          },
          {
            name: "智能预警，防灾减灾",
            content: "基于大数据和人工智能分析，预测水灾风险，提供及时预警和应急指引，减少灾害损失，提升应急响应能力。",
            image: "./img/scys11.png",
          },
          {
            name: "数据整合，科学管理",
            content: "采整合气象、水文、用水等多源数据，采用数据分析和可视化技术，支持科学决策，提升水资源管理的精度和效率。",
            image: "./img/scys12.png",
          },
        ],
      })}
      {headerModule("业务架构", "ARCHITECTURE")}
      {picModule({ image: "./img/internal-programs/jiagou_shuili.png", name: "云原生" })}
      {headerModule("细分领域", "SUBDIVISION")}
      {picModule({ image: "./img/internal-programs/zhihuishuili.png", name: "云原生" })}
      {headerModule("典型案例", "TYPICAL CASE")}
      {videoModule(
        {
          name: "杭州某水闸<br/>智慧水闸平台",
          description: `利用物联网、大数据和人工智能技术，显著提升了水闸管理的智能化和效率。该项目旨在实现水闸的实时监测和自动控制，通过传感器网络对水位、水流和设备状态进行全方位监控。数据分析与预测功能提高了水闸调度的精准度，有效预防了洪涝灾害的发生。远程控制系统则使得管理人员能够及时响应紧急情况，提高了水闸的运行效率和安全性。项目通过设备安装、系统集成和智能平台建设，为城市防洪抗灾提供了强有力的技术支持，同时为其他地区智慧水利建设提供了示范和借鉴。`,
          video: "https://obs.talsys.cn/demo_video/%E6%99%BA%E6%85%A7%E6%B0%B4%E5%88%A9_%E6%9F%90%E5%9C%B0%E5%8C%BA%E6%B0%B4%E9%97%B8.mp4",
        },
        true
      )}
      {videoModule(
        {
          name: "虎山水文站<br/>数字孪生平台",
          description: `项目实现了对虎山水文站的实时监测和数据展示。该平台利用先进的物联网技术、数据采集设备以及3D建模技术，对水文站的关键数据进行实时收集和动态呈现。平台界面友好，操作简便，可以实时展示水位、流量、降雨量等重要水文数据，并通过3D模型直观呈现站点及其周边环境。项目的实施不仅提高了水文数据的监测精度和响应速度，还为防洪决策和应急调度提供了重要支持。通过该项目，水利部门能够更有效地管理和利用水文资源，提高防汛抗灾能力。`,
          video: "https://obs.talsys.cn/demo_video/%E6%99%BA%E6%85%A7%E6%B0%B4%E5%88%A9_%E8%99%8E%E5%B1%B1%E6%B0%B4%E6%96%87%E7%AB%99.mp4",
        },
        false
      )}
    </div>
  );
}

export default intelligentCity;
